import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

const BarChartComponent = ({ data, color, chartKeyData }) => {
  return (
    <ResponsiveContainer>
          <BarChart data={data} margin={{ left: 10, right: 10, top: 10 }}>
            <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
            <XAxis dataKey="label" />
            <YAxis width={30} dataKey="value" />
            <Tooltip content={<CustomTooltip chartKeyData={chartKeyData} />} />
            <Bar dataKey="value" fill={color || "#698cf5"} isAnimationActive />
          </BarChart>
        </ResponsiveContainer>
  );
};

export default BarChartComponent;
