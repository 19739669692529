import { CHARTS } from "../common/defaults/charts.defaults";

const getActivitesByPeriod = (activities, period, key) => {
  const currentDate = new Date(); // Current date

  // Filter by period
  const filteredActivities = activities.filter((activity) => {
    const activityDate = new Date(activity.start_date); // Date of the activity
    const activityMonth = activityDate.getMonth(); // Month of the activity
    const activityYear = activityDate.getFullYear(); // Year of the activity

    if (period === "week") {
      const weekStart = new Date(currentDate); // Get a copy of the current date
      weekStart.setDate(currentDate.getDate() - currentDate.getDay() + 1); // Adjust to Monday of the week
      weekStart.setHours(0, 0, 0, 0);

      return activityDate >= weekStart && activityDate <= currentDate;
    } else if (period === "month") {
      return (
        activityYear === currentDate.getFullYear() &&
        activityMonth === currentDate.getMonth()
      );
    } else if (period === "year") {
      return activityYear === currentDate.getFullYear();
    }
    return false;
  });

  return filteredActivities;
};
const getDataByKeyAndPeriod = (activities, period, key) => {
  const currentDate = new Date(); // Current date

  // Filter by period
  const filteredActivities = getActivitesByPeriod(activities, period, key);

  const distances = [];

  if (period === "week") {
    const distanceThisWeek = Array.from({ length: 7 }, () => 0); // Initialize array for the week
    filteredActivities.forEach((activity) => {
      const activityDate = new Date(activity.start_date);
      const activityDay = activityDate.getDay(); // Day of the activity (0 = Sunday, 1 = Monday, etc.)
      distanceThisWeek[activityDay - 1] += activity[key] || 0;
    });
    distances.push(...distanceThisWeek);
  } else if (period === "month") {
    const daysInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0,
    ).getDate();
    const distancePerDay = Array.from({ length: daysInMonth }, () => 0); // Initialize array for the month
    filteredActivities.forEach((activity) => {
      const activityDate = new Date(activity.start_date);
      const activityDay = activityDate.getDate() - 1; // Day index within the month
      distancePerDay[activityDay] += activity[key] || 0;
    });
    distances.push(...distancePerDay);
  } else if (period === "year") {
    const distancePerMonth = Array.from({ length: 12 }, () => 0); // Initialize array for the year
    filteredActivities.forEach((activity) => {
      const activityMonth = new Date(activity.start_date).getMonth(); // Month of the activity
      distancePerMonth[activityMonth] += activity[key] || 0;
    });
    distances.push(...distancePerMonth);
  }

  return distances;
};

const getDataByKeyAndPeriodPieChart = (activities, period, key) => {
  const filteredActivities = getActivitesByPeriod(
    activities,
    period,
    key,
  ).filter((val) => ["Run", "Ride", "Swim"].includes(val.type));

  if (filteredActivities.length > 0) {
    const data = filteredActivities.reduce((acc, value) => {
      if (acc[value.type]) {
        acc[value.type] += value[key];
      } else {
        acc[value.type] = value[key];
      }
      return acc;
    }, {});

    const totalDistance = Object.values(data).reduce(
      (acc, distance) => acc + distance,
      0,
    );
    const percentages = {};

    for (const sport in data) {
      percentages[sport] = +((data[sport] / totalDistance) * 100).toFixed(2);
    }

    return Object.keys(percentages).map((key) => ({
      name: key,
      value: percentages[key],
    }));
  }

  return [];
};

const getLabelByPeriodAndIndex = (period, index) => {
  switch (period) {
    case "week":
      return CHARTS.WEEK[index];
    case "year":
      return CHARTS.YEAR[index];
    default:
      return index + 1;
  }
};

const getValueParsedForKey = (value, key) => {
  switch (key) {
    case "distance":
      return Number((value / 1000).toFixed(2));
    case "moving_time":
      return value / 60;
    default:
      return value;
  }
};

export const getDataForChart = (activities, period, key) => {
  switch (key) {
    case "distance":
    case "moving_time":
      return getDataByKeyAndPeriod(activities, period, key || "distance").map(
        (value, index) => ({
          label: getLabelByPeriodAndIndex(period, index),
          value: getValueParsedForKey(value, key),
        }),
      );
    case "distance-pie":
    case "moving_time-pie":
      return getDataByKeyAndPeriodPieChart(
        activities,
        period,
        key.split("-")[0],
      );

    default:
      return [];
  }
};
