import { Tab, Tabs } from "@mui/material";

const CustomTabs = ({ value, handleChangeValue, tabsData }) => {
  return (
    <Tabs
      value={value}
      onChange={(_, value) => handleChangeValue(value)}
      aria-label="sport type"
      sx={{
        minHeight: "unset",
        ".MuiTabs-flexContainer": {
          gap: "5px",
          flexWrap: "wrap"
        },
      }}
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
    >
      {tabsData.map((data) => (
        <Tab
          value={data.label}
          sx={{
            border: "1px solid rgba(0,0,0,.1)",
            borderRadius: "5px",
            padding: "4px 6px",
            boxSizing: "border-box",
            minHeight: "unset",
            background: "#F2F4F8",
            fontSize: "13px",
            textAlign: "center",
            svg: {
              height: "20px"
            },
            "&.Mui-selected": {
              backgroundColor: data.background ? data.background : "lightblue",
              color: "black",
              "&:hover": {
                background: data.background,
              }
            },
            "&:hover": {
              background: "rgba(0,0,0,.05)",
            }
          }}
          icon={data.icon}
          iconPosition="start"
          label={data.label}
        />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
