import { Box } from "@mui/material";
import React from "react";
import LastActivity from "./lastActivity";
import LastWeek from "./lastWeek";

const HistorySection = () => {
  return (
    <>
      <Box sx={{ flex: 2 }} gridArea="d">
        <LastWeek />
      </Box>
      <Box sx={{ flex: 1 }} gridArea="e">
        <LastActivity />
      </Box>
    </>
  );
};

export default HistorySection;
