import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

const AreaChartComponent = ({ data, color, chartKeyData }) => {
  return (
    <ResponsiveContainer>
      <AreaChart data={data} margin={{ left: 10, right: 10, top: 10 }}>
        <defs>
        <linearGradient id="red" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="red" stopOpacity={0.8} />
            <stop offset="100%" stopColor="red" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="blue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="100%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="100%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
        <XAxis dataKey="label" />
        <YAxis width={30} dataKey="value" />
        <Tooltip content={<CustomTooltip chartKeyData={chartKeyData} />} />
        <Area type="monotone" dataKey="value" stroke={color} fillOpacity={1} fill={`url(#${color})`} />
        {/* <Area
          dataKey="value"
          fill={color || "#698cf5"}
          stroke={color || "#7a8eca"}
          isAnimationActive
        /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartComponent;
