import BoltIcon from "@mui/icons-material/Bolt";
import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { lastActivityData } from "./data";

const LastActivity = () => {
  const lastActivity =
    useSelector((state) => state.userReducer.activities[0]) || {};

  return (
    <Paper
      sx={{
        boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)",
        padding: "10px",
        color: "gray",
        borderRadius: "10px",
      }}
    >
      <Stack spacing={3}>
        <Typography
          variant="h5"
          sx={{ fontSize: "16px", fontWeight: "bold", height: "32px" }}
        >
          <BoltIcon sx={{ verticalAlign: "middle" }} /> Last Activity
        </Typography>
        <Stack spacing={2}>
          {[...lastActivityData(lastActivity)].map(
            ({ label, value, icon }, index, { length }) => (
              <>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  {icon}
                  <Typography
                    variant="h6"
                    sx={{ textWrap: "nowrap", fontSize: "16px" }}
                  >
                    {label}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontWeight: "bold",
                      marginLeft: "auto",
                      fontSize: "16px",
                    }}
                  >
                    {value}
                  </Typography>
                </Box>
                {index !== length - 1 && (
                  <Divider sx={{ width: "100%", height: 2 }} />
                )}
              </>
            ),
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default LastActivity;
