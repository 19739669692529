export const data = [
  {
    name: 'Sleep - 8h',
    data: 90,
    fill: '#8884d8',
  },
  {
    name: 'Calories - 2500',
    data: 70,
    
    fill: '#83a6ed',
  },
  {
    name: 'Active Time - 1h',
    data: 80,
    
    fill: '#8dd1e1',
  },
  {
    name: 'Steps - 10k',
    data: 60,
    
    fill: '#82ca9d',
  },
  
];