import { Box, Grid, Typography } from "@mui/material";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import PoolIcon from "@mui/icons-material/Pool";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import { COLORS } from "../../../common/defaults/colors.defaults";

const EventHeader = ({ icon, label }) => (
  <Grid
    item
    sx={{
      display: "flex",
      placeContent: "center",
      gap: "2px",
      padding: "2px",
      svg: { height: "18px" },
    }}
  >
    {icon}
    <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">
      {label}
    </Typography>
  </Grid>
);

const getDataBySport = (activityType) => {
  if (activityType.includes("Run")) {
    return {
      icon: <DirectionsRunIcon />,
      label: activityType,
      color: COLORS.SPORT.RUN,
    };
  } else if (activityType.includes("Ride")) {
    return {
      icon: <DirectionsBikeIcon />,
      label: activityType,
      color: COLORS.SPORT.RIDE,
    };
  } else if (activityType.includes("Swim")) {
    return {
      icon: <PoolIcon />,
      label: activityType,
      color: COLORS.SPORT.SWIM,
    };
  } else {
    return {
      icon: <FitnessCenterOutlinedIcon />,
      label: activityType,
      color: COLORS.SPORT.STRENGHT,
    };
  }
};

const CustomEvent = ({ event }) => {
  const { activityType, title, activityTime, activityIndications } = event;
  const { icon, label, color } = getDataBySport(activityType);

  return (
    <Grid
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: color,
          display: "flex",
          placeItems: "center",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <EventHeader icon={icon} label={label} />
      </Box>
      <Box
        padding={"5px"}
        backgroundColor={"rgb(241, 241, 241)"}
        borderRadius="0 0 5px 5px"
      >
        <Typography fontSize={"14px"} fontWeight="600">
          {title}
        </Typography>
        <Typography
          fontSize={"12px"}
          sx={{
            display: "-webkit-box",
            "-webkit-line-clamp": "3" /* Adjust as needed */,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            whiteSpace: "initial",
          }}
        >
          {activityTime && (
            <>
              <b>Time:</b> {activityTime}
            </>
          )}
        </Typography>
        <Typography
          fontSize={"12px"}
          sx={{
            display: "-webkit-box",
            "-webkit-line-clamp": "3" /* Adjust as needed */,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            whiteSpace: "initial",
          }}
        >
          {activityIndications || "-"}
        </Typography>
      </Box>
    </Grid>
  );
};

export default CustomEvent;
