import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import garminIcon from "../../assets/garmin-icon.svg";
import stravaIcon from "../../assets/strava-icon.svg";
import { getGarminToken } from "../../services/getGarminToken.service";
import { generateGarminProviderUrl } from "../../utils/generateGarminProviderUrl.util";
import { generateStravaProviderUrl } from "../../utils/generateStravaProviderUrl.uti";
import { jwtExtractorUtil } from "../../utils/jwtExtractorUtil.util";
import LinkButton from "../Buttons/LinkButton";
import { providers } from "./data";

const AuthProvidersButtons = () => {
  const [garminProviderUrl, setGarminProviderUrl] = useState("");
  const userId = jwtExtractorUtil(localStorage.accessToken).sub;
  const stravaProviderUrl = generateStravaProviderUrl(userId);

  useEffect(() => {
    getGarminProviderUrl();
  }, []);

  const getGarminProviderUrl = async () => {
    const garminToken = await getGarminToken(localStorage.accessToken);
    const garminUrl = generateGarminProviderUrl(garminToken);
    setGarminProviderUrl(garminUrl);
  };

  return (
    <Grid
      container
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(228px, 1fr))",
        gap: "10px",
        margin: "0 auto",
      }}
    >
      <LinkButton to={stravaProviderUrl}>
        <img src={stravaIcon} alt="Strava Icon" width={100} />
      </LinkButton>
      <LinkButton to={garminProviderUrl}>
        <img src={garminIcon} alt="Garmin Icon" width={100} />
      </LinkButton>
      {providers.map(({ image, alt }) => {
        return (
          <LinkButton key={alt} to={"/"} target="_self">
            <img src={image} alt={alt} width={100} />
          </LinkButton>
        );
      })}
      <LinkButton to={"/"} target="_self">
        <LibraryAddIcon sx={{ width: 100, height: 100 }} />
      </LinkButton>
    </Grid>
  );
};

export default AuthProvidersButtons;
