import React from "react";
import { useSelector } from "react-redux";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Card from "../../../Card/Card";
import NoDataComponent from "../../../NoDataComponent/NoDataComponent";
import { data } from "./data";
import { off } from "./utils";

const Fitness = () => {
  const activities = useSelector((state) => state.userReducer.activities);

  return (
    <Card title="User form">
      {activities.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
            baseValue={50}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset={off} stopColor="green" stopOpacity={1} />
                <stop offset={off} stopColor="red" stopOpacity={1} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="form"
              stroke="#000"
              fill="url(#splitColor)"
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <NoDataComponent />
      )}
    </Card>
  );
};

export default Fitness;
