import { Box, Stack } from "@mui/material";
import React from "react";
import CustomCarousel from "../../components/UI/Carousel/Carousel";
import HistorySection from "../../components/home/historySection";
import OverallSection from "../../components/home/overallSection";

const Homepage = () => {
  return (
    <Stack spacing={4} sx={{ justifyItems: "center", alignItems: "center" }}>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gap: 2,
          gridTemplateAreas: `"a b c" "d d e"`,
          "@media (max-width:800px)": {
            gridTemplateAreas: `"a" "b" "c" "d" "e"`,
          },
        }}
      >
        <OverallSection />
        <HistorySection />
      </Box>
      <CustomCarousel>
        <div className="slide-holder">
          <img
            alt=""
            src="https://appdazzle.net/wp-content/uploads/2016/04/Garmin-Apps-for-iPhone.jpg"
          />
          <div className="text-container">
            <h2>GARMIN</h2>
            <p>
              Garmin Ltd. is an American, Swiss-domiciled multinational
              technology company founded in 1989 by Gary Burrell and Min Kao in
              Lenexa, Kansas, United States, with headquarters in Olathe,
              Kansas. Since 2010, the company is legally incorporated in
              Schaffhausen, Switzerland.
            </p>
          </div>
        </div>
        <div className="slide-holder">
          <img
            alt=""
            src="https://www.shyftup.com/wp-content/uploads/2023/09/Strava-Screenshots.png"
          />
          <div className="text-container">
            <h2>STRAVA</h2>
            <p>
              Strava is an American internet service for tracking physical
              exercise which incorporates social network features. It started
              out tracking mostly outdoor cycling and running activities using
              Global Positioning System data, but now incorporates several dozen
              other exercise types, including indoor activities.
            </p>
          </div>
        </div>
        <div className="slide-holder">
          <img
            alt=""
            src="https://www.noble-pro.com/wp-content/uploads/2022/04/zwift-article-.png"
          />
          <div className="text-container">
            <h2>ZWIFT</h2>
            <p>
              Zwift is a massively multiplayer online cycling and running
              physical training program that enables users to interact, train,
              and compete in a virtual world.{" "}
            </p>
          </div>
        </div>
      </CustomCarousel>
    </Stack>
  );
};

export default Homepage;
