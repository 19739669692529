export const ModalDataActionsType = {
  SET_ADD_EVENT_MODAL: "SET_ADD_EVENT_MODAL",
  RESTART_MODAL_STATE: "RESTART_MODAL_STATE",
};

export const setAddEventModalAC = (payload) => ({
  type: ModalDataActionsType.SET_ADD_EVENT_MODAL,
  payload: payload,
});

export const restartModalStateAC = () => ({
  type: ModalDataActionsType.RESTART_MODAL_STATE,
});
