import axios from "axios";
import { API_BASE_URL } from "../common/defaults/urls.defaults";

const fetchDataFromBackend = async (endpoint, method = "GET", data = {}) => {
  try {
    const jwtToken = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${jwtToken}`, // Include JWT in the Authorization header
      "Content-Type": "application/json", // Adjust content type as needed
    };

    const options = {
      headers,
      method, // HTTP method (GET, POST, etc.)
      url: `${API_BASE_URL}${endpoint}`, // Construct the full endpoint URL
    };

    if (method !== "GET") {
      options.data = data; // Include data for non-GET requests
    }

    const response = await axios(options);

    return response.data; // Return the fetched data
  } catch (error) {
    // Handle errors, e.g., log them or throw for further handling
    console.error("Error fetching data:", error);
    return;
    // throw error;
  }
};

export default fetchDataFromBackend;
