const CustomTooltip = ({ active, payload, label, chartKeyData }) => {
  const dataToShow = (value) => {
    switch (chartKeyData) {
      case "distance":
        return `${value} km`;
      case "moving_time":
        const hours = Math.floor(value / 60);
        const remainingMinutes = (value % 60).toFixed(0);
        return `${hours}h ${remainingMinutes}m`;
      default:
        return value;
    }
  };
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          background: "white",
          padding: "10px",
          border: "1px solid gray",
          borderRadius: "5px",
        }}
      >
        <p className="label">
          {label
            ? `${label}: ${dataToShow(payload[0].value)}`
            : dataToShow(payload[0].value)}
        </p>
        <div>
          {/* {payload.map((pld) => (
            <div style={{ display: "inline-block", padding: 10 }}>
              <div style={{ color: pld.fill }}>{pld.value}</div>
              <div>{pld.dataKey}</div>
            </div>
          ))} */}
        </div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
