import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import PoolIcon from "@mui/icons-material/Pool";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { COLORS } from "./colors.defaults";

export const TABS = {
  SPORTS_TYPE: [
    { icon: <DirectionsRunIcon />, label: "Run", background: COLORS.SPORT.RUN },
    {
      icon: <DirectionsBikeIcon />,
      label: "Ride",
      background: COLORS.SPORT.RIDE,
    },
    { icon: <PoolIcon />, label: "Swim", background: COLORS.SPORT.SWIM },
    {
      icon: <FitnessCenterOutlinedIcon />,
      label: "Strength",
      background: COLORS.SPORT.STRENGHT,
    },
  ],
  OTHER_TYPE: [
    { icon: <TextSnippetIcon />, label: "Note" },
    { icon: <EmojiEventsIcon />, label: "Event" },
  ],
};
