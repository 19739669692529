import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export const MixBarChart = ({ data }) => (
  <ResponsiveContainer width="100%" height="100%">
    <BarChart
      data={data}
      margin={{ left: 10, right: 10, top: 10 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis width={30} />
      <Tooltip />
      <Legend />
      <Bar dataKey="proteins" stackId="a" fill="#8884d8" />
      <Bar dataKey="fat" stackId="a" fill="#82ca9d" />
      <Bar dataKey="carbs" stackId="a" fill="#ffc658" />
    </BarChart>
  </ResponsiveContainer>
);
