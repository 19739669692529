import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import HotelIcon from "@mui/icons-material/Hotel";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
export const lastWeekData = (activities) => [
  {
    label: "Total time",
    value: activities ? "10h 30m" : "N/A",
    icon: <AccessTimeIcon />,
  },
  {
    label: "Total distance",
    value: activities ? "37 km" : "N/A",
    icon: <SocialDistanceIcon />,
  },
  {
    label: "Average steps",
    value: activities ? 6534 : "N/A",
    icon: <DirectionsWalkIcon />,
  },
  {
    label: "Average sleep",
    value: activities ? "6h 30m" : "N/A",
    icon: <HotelIcon />,
  },
  {
    label: "Average calories",
    value: activities ? "2100 kcal" : "N/A",
    icon: <LocalFireDepartmentIcon />,
  },
];
