import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Paper, Typography, Box } from "@mui/material";
import wallpaperImage from "../../assets/login-wallpaper.jpg";
import { ReactComponent as Logo } from "../../assets/logo.svg";

const PaperStyled = styled(Paper)({
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  "@media (max-width:600px)": {
    padding: "80px 40px 40px",
  },
  "@media (min-width:600px)": {
    padding: "80px",
  },
  boxShadow: "none",
});

const Image = styled("div")({
  background: `url(${wallpaperImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100vh",
});

const AuthenticationLayout = ({ title, children }) => {
  return (
    <Grid container>
      <Box sx={{ position: "absolute", top: "14px", left: "24px" }}>
        <Logo height={40} width="auto" />
      </Box>
      <Grid sx={{ display: { xs: "none", sm: "none", md: "block" }, flex: 1 }}>
        <Image />
      </Grid>
      <Grid
        sx={{ margin: "auto", width: { xs: "100%", sm: "100%", md: "650px" } }}
      >
        <PaperStyled>
          <Typography
            variant="h1"
            sx={{ fontWeight: "700", fontSize: "42px" }}
            gutterBottom
          >
            {title}
          </Typography>
          {children}
        </PaperStyled>
      </Grid>
    </Grid>
  );
};

export default AuthenticationLayout;
