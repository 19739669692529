import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  InputLabel,
  Grid,
} from "@mui/material";
import {
  Search as SearchIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";

const CustomTable = ({
  header,
  rows,
  showIndex,
  isLinkFirst,
  handleClickLink,
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [searchTerm, setSearchTerm] = useState("");

  function descendingComparator(a, b, orderBy) {
    if (a[orderBy] === undefined) {
      return -1;
    }
    if (b[orderBy] === undefined) {
      return 1;
    }
    if (
      b[orderBy].toString().toUpperCase() < a[orderBy].toString().toUpperCase()
    ) {
      return -1;
    }
    if (
      b[orderBy].toString().toUpperCase() > a[orderBy].toString().toUpperCase()
    ) {
      return 1;
    }
    return 0;
  }

  const sortedData = () => {
    let sorted = [...rows];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        return sortConfig.direction === "desc"
          ? descendingComparator(a, b, sortConfig.key)
          : -descendingComparator(a, b, sortConfig.key);
      });
    }
    // Search on all keys
    //   return sorted.filter((item) =>
    //   Object.values(item).some((value) =>
    //     String(value).toLowerCase().includes(searchTerm.toLowerCase())
    //   )
    // );
    if (!searchTerm) {
      return sorted;
    }
    return sorted.filter((item) =>
      String(item.name).toLowerCase().includes(searchTerm.toLowerCase()),
    );
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box width={"100%"}>
      <Grid
        sx={{
          display: "flex",
          placeItems: "center",
          gap: "10px",
          marginBottom: "20px",
          zIndex: 1,
        }}
      >
        <InputLabel>Search:</InputLabel>
        <TextField
          sx={{ backgroundColor: "#F2F4F8", button: { right: "10px" } }}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {(showIndex ? ["", ...header] : header).map(
                (headerElement, index) => (
                  <TableCell
                    key={`header-${headerElement?.key || index}`}
                    onClick={() => requestSort(headerElement.key)}
                    sx={index !== 0 ? { cursor: "pointer" } : { width: "20px" }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography>{headerElement.label}</Typography>
                      <Box
                        sx={{
                          width: "16px",
                          height: "16px",
                          svg: { height: "16px", width: "auto" },
                        }}
                      >
                        {index !== 0 &&
                          sortConfig.key === headerElement.key &&
                          (sortConfig.direction === "asc" ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          ))}
                      </Box>
                    </Box>
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData().map((row, index) => (
              <TableRow
                key={`activity-row-${index}`}
                sx={index % 2 === 0 ? { backgroundColor: "#DDE1E680" } : {}}
              >
                {showIndex && (
                  <TableCell key={`showIndex-${index}`}>{index + 1}</TableCell>
                )}
                {Object.values(row).map((value, i) =>
                  isLinkFirst && i === 0 ? (
                    <TableCell
                      key={i}
                      onClick={() => handleClickLink(index)}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {value}
                    </TableCell>
                  ) : (
                    <TableCell key={i}>{value}</TableCell>
                  ),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomTable;
