import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

const LineChartComponent = ({ data, color, chartKeyData }) => {
  return (
    <ResponsiveContainer>
      <LineChart data={data} margin={{ left: 10, right: 10, top: 10 }}>
        <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
        <XAxis dataKey="label" />
        <YAxis width={30} dataKey="value" />
        <Tooltip content={<CustomTooltip chartKeyData={chartKeyData} />} />
        <Line
          type="monotone"
          dataKey="value"
          stroke={color || "#698cf5"}
          dot={true}
          isAnimationActive
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
