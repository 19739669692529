import { Box, Button, InputLabel, TextField } from "@mui/material";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { TABS } from "../../common/defaults/tabs.defaults";
import CustomModal from "../../components/Modal/CustomModal";
import Schadule from "../../components/Schadule/Schadule";
import CustomTabs from "../../components/Tabs/Tabs";
import { generateTrainingPlan } from "../../services/generateTrainingPlan";
import { setEventsAC } from "../../state/actions/user.actions";

const TrainingPlans = ({ events }) => {
  const [isLoadingPlan, setLoadingPlan] = useState(false);
  const [planModalOpen, setPlanModalOpen] = useState(false);
  const allActivities = useSelector((state) => state.userReducer.activities);
  const trainingPlan = useSelector((state) => state.userReducer.trainingPlan);
  const [goal, setGoal] = useState({});

  const dispatch = useDispatch();

  const handleOpenGeneratePlan = () => {
    setPlanModalOpen(true);
    setGoal({});
  };

  const handleChangeValue = (key, value) => {
    setGoal((state) => ({ ...state, [key]: value }));
  };

  const handleGeneratePlan = async () => {
    setLoadingPlan(true);
    const plan = await generateTrainingPlan(
      `${goal.date || "20"} days`,
      `${goal.distance || "Running"} ${goal.distance || "21"} km`,
      goal.time || "1h and 30m",
      allActivities,
    );
    setLoadingPlan(false);
    plan && dispatch(setEventsAC([...plan]));
  };
  return (
    <>
      <Button
        onClick={() => handleOpenGeneratePlan()}
        sx={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          width: "100%",
          background: "transparent",
          color: "#373a3c"
        }}
      >
        {trainingPlan.length > 0 ? "Generate another plan" : "Generate plan"}
      </Button>
      <Schadule
        isLoading={isLoadingPlan}
        events={(events || []).map((event) => ({
          ...event,
          start: new Date(event.date),
          end: new Date(event.date),
        }))}
      />
      <CustomModal
        open={planModalOpen}
        setOpen={setPlanModalOpen}
        title={"Generate Plan"}
        butonLabel="Generate"
        handleSave={() => {
          handleGeneratePlan();
          setPlanModalOpen(false);
        }}
      >
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <InputLabel>Time</InputLabel>
              <TextField
                value={goal.time || ""}
                placeholder={"1h and 30m"}
                onChange={(e) => {
                  handleChangeValue("time", e.target.value);
                }}
                className={"add-event__time"}
              />
            </Box>
            <Box>
              <InputLabel>Distance</InputLabel>
              <TextField
                placeholder={"0.0"}
                value={goal.distance || ""}
                onChange={(e) => handleChangeValue("distance", e.target.value)}
                className={"add-event__distance"}
              />
            </Box>
          </Box>
          <br />
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              <InputLabel>Date Range</InputLabel>
              <TextField
                value={goal.date || ""}
                placeholder={"10"}
                onChange={(e) => {
                  handleChangeValue("date", e.target.value);
                }}
                className={"add-event__days"}
              />
            </Box>
          </Box>
          <br />
          <InputLabel htmlFor="select">Activity Type</InputLabel>
          <CustomTabs
            value={goal.type || ""}
            handleChangeValue={(value) => handleChangeValue("type", value)}
            tabsData={TABS.SPORTS_TYPE}
          />
          <br />
        </>
      </CustomModal>
    </>
  );
};

const mapStateToProps = (state) => ({
  events: [...state.userReducer.trainingPlan],
});

export default connect(mapStateToProps)(TrainingPlans);
