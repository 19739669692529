import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  setBreakPointAC,
  setLayoutAC,
} from "../../state/actions/layout.actioms";
import GridLayoutHeader from "./GridLayoutHeader/GridLayoutHeader";
import ChartComponent from "../ChartComponent/ChartComponent";
import { getDataForChart } from "../../services/getDataForCharts.service";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import { Box } from "@mui/system";
import NoDataComponent from "../NoDataComponent/NoDataComponent";

const ResponsiveGridLayout = WidthProvider(Responsive);

const GridLayout = ({ charts, layouts, breakpoint, activities }) => {
  const dispatch = useDispatch();

  const handleBreakPointChange = (breakpoint) => {
    dispatch(setBreakPointAC(breakpoint));
  };

  const handleLayoutChange = (newLayout) => {
    dispatch(setLayoutAC(breakpoint, newLayout));
  };

  const getChartByType = (type, color, key, dataRange) => {
    const data = getDataForChart(activities, dataRange, key);

    if (activities.length > 0 && ((type === "PIE" && data.length > 0) || type !== "PIE"))
      return (
        <ChartComponent
          type={type}
          color={color}
          chartKeyData={key}
          isDashboard
          data={data}
        />
      );
    return <NoDataComponent />;
  };

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={layouts}
      onBreakpointChange={handleBreakPointChange}
      onLayoutChange={handleLayoutChange}
      isDraggable
      isRearrangeable
      isResizable
      draggableHandle=".drag-handle"
      breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      useCSSTransforms
      margin={[15, 15]}
    >
      {Object.keys(charts).map((chartId) => {
        const { title, type, color, dataRange, key } = charts[chartId];

        return (
          <div id={chartId} key={chartId} className={`grid-item`}>
            <GridLayoutHeader
              title={title + (dataRange ? ` - ${dataRange.toUpperCase()}` : "")}
              type={type}
              color={color}
              dataRange={dataRange}
              chartId={chartId}
            />
            <div className="grid-item__graph">
              {type ? (
                getChartByType(type, color, key, dataRange)
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    placeItems: "center",
                    height: "100%",
                  }}
                >
                  <AddchartOutlinedIcon
                    sx={{
                      color: "gray",
                      width: "100px",
                      height: "100px",
                      opacity: 0.5,
                    }}
                  />
                </Box>
              )}
            </div>
          </div>
        );
      })}
    </ResponsiveGridLayout>
  );
};

GridLayout.propTypes = {
  charts: PropTypes.object.isRequired,
  layouts: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  charts: { ...state.layoutReducer.charts },
  layouts: { ...state.layoutReducer.layouts },
  breakpoint: state.layoutReducer.breakpoint,
  activities: [...state.userReducer.activities],
});

export default connect(mapStateToProps)(GridLayout);
