import React, { useState } from "react";
import PropTypes from "prop-types";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import CustomModal from "../../Modal/CustomModal";
import GridLayoutEdit from "../GridLayoutEdit/GridLayoutEdit";
import { Box } from "@mui/material";

const GridLayoutHeader = ({ chartId, title, type, color, dataRange }) => {
  const [editOpen, setEditOpen] = useState(false);

  return (
    <div className="grid-item__header">
      <p>{title}</p>
      <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
        <EditIcon
          onClick={() => {
            setEditOpen(true);
          }}
        />
        <DragIndicatorIcon className="drag-handle" />
      </Box>

      <CustomModal open={editOpen} setOpen={setEditOpen} title={title}>
        <GridLayoutEdit
          chartId={chartId}
          title={title}
          type={type || ""}
          color={color || ""}
          dataRange={dataRange || ""}
        />
      </CustomModal>
    </div>
  );
};

GridLayoutHeader.propTypes = {
  chartId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default GridLayoutHeader;
