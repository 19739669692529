import fetchDataFromBackend from "./fetchDataFromBackend.service";
import { getStringTimeFromMinutes } from "./manipulateData.service";

const createPastActivitiesPayload = (pastActivities) => {
  return pastActivities.map((activity) => ({
    ActivityType: activity.type,
    Distance: `${activity.distance} m`,
    ActivityTime: getStringTimeFromMinutes(activity.elapsed_time),
    Date: activity.start_date,
  }));
};

export const generateTrainingPlan = async (
  timeFrame,
  activity,
  activityTime,
  allActivities
) => {
  try {
    const last30Activities = allActivities.slice(0, 30);
    const payload = {
      timeFrame,
      activity,
      activityTime,
      pastActivities: [...createPastActivitiesPayload(last30Activities)],
    };

    const res = await fetchDataFromBackend("/services/plan", "POST", payload);

    return res.plan;
  } catch (err) {
    console.log(err);
  }
};
