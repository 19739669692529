import { Link as MuiLink, Paper } from "@mui/material";
import { Link } from "react-router-dom";

const LinkButton = ({ children, to, target = "_blank", ...props }) => {
  return (
    <MuiLink
      component={Link}
      to={to}
      target={target}
      sx={{
        textDecoration: "none",
      }}
    >
      <Paper
        {...props}
        sx={{
          padding: 8,
          borderRadius: "10px",
          display: "flex",
          placeItems: "center",
          flexDirection: "column",
          textDecoration: "none",
          transition: "transform 0.3s ease-in-out",
          boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)",
          "&:hover": {
            transform: "scale(1.05)",
            transition: "transform 0.3s ease-in-out",
            cursor: "pointer",
          },
        }}
      >
        {children}
      </Paper>
    </MuiLink>
  );
};

export default LinkButton;
