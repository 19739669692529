import { Box, Grid, styled } from "@mui/material";
import { useSelector } from "react-redux";
import Card from "../../components/Card/Card";
import ChartComponent from "../../components/ChartComponent/ChartComponent";
import { CustomActiveShapePieChart } from "../../components/ChartComponent/CustomActiveShapePieChart/CustomActiveShapePieChart";
import { MixBarChart } from "../../components/ChartComponent/MixBarChart/MixBarChart";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import {
  mockHRV,
  mockMacronutrients,
  mockOverallWellbeing,
  mockSleep,
  moockStressLevel,
} from "../../data/mockData";

const Layout = styled(Grid)({
  display: "grid",
  gap: 15,
  gridTemplateRows: "auto",
  gridTemplateAreas: `"a b" "c c" "d d" "e e"`,
  "@media (max-width:1130px)": {
    gridTemplateAreas: `"a" "b" "c" "d" "e"`,
  },
});

const Wellbeing = () => {
  const isNewUser =
    useSelector((state) => state.userReducer.activities).length === 0;

  return (
    <Layout>
      <Box gridArea="a">
        <Card title={"Overall Well-being"}>
          {!isNewUser ? (
            <CustomActiveShapePieChart data={mockOverallWellbeing} />
          ) : (
            <NoDataComponent />
          )}
        </Card>
      </Box>
      <Box gridArea="b">
        <Card title={"Sleep Analysis"}>
          {!isNewUser ? (
            <ChartComponent type={"PIE"} data={mockSleep} />
          ) : (
            <NoDataComponent />
          )}
        </Card>
      </Box>
      <Box gridArea="c">
        <Card title={"Macronutrients"}>
          {!isNewUser ? (
            <MixBarChart data={mockMacronutrients} />
          ) : (
            <NoDataComponent />
          )}
        </Card>
      </Box>
      <Box gridArea="d">
        <Card title={"Stress levels monitoring"}>
          {!isNewUser ? (
            <ChartComponent
              type={"BAR"}
              data={moockStressLevel}
              color="orange"
            />
          ) : (
            <NoDataComponent />
          )}
        </Card>
      </Box>
      <Box gridArea="e">
        <Card title={"Heart rate variability (HRV)"}>
          {!isNewUser ? (
            <ChartComponent type={"LINE"} data={mockHRV} />
          ) : (
            <NoDataComponent />
          )}
        </Card>
      </Box>
    </Layout>
  );
};

export default Wellbeing;
