import { Box, InputLabel, TextField } from "@mui/material";
import { TextAreaField } from "@aws-amplify/ui-react";
import CustomTabs from "../../Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { setAddEventModalAC } from "../../../state/actions/modalData.actions";
import { useEffect } from "react";
import { TABS } from "../../../common/defaults/tabs.defaults";

const ManageEvent = ({ date }) => {
  const {
    activityType,
    activityTime,
    distance,
    speed,
    elevation,
    activityIndications,
  } = useSelector((state) => state.moadlDataReducer.addEventModal);

  const dispatch = useDispatch();

  useEffect(() => {
    handleChangeValue("date", date);

    // eslint-disable-next-line
  }, []);

  const handleChangeValue = (key, value) => {
    dispatch(setAddEventModalAC({ key, value }));
  };

  return (
    <div className="add-event">
      <Box sx={{ display: "flex", gap: "20px" }}>
        <Box>
          <InputLabel>Time</InputLabel>
          <TextField
            value={activityTime}
            placeholder={"00:00:00"}
            onChange={(e) => {
              handleChangeValue("activityTime", e.target.value);
            }}
            className={"add-event__time"}
          />
        </Box>
        <Box>
          <InputLabel>Distance</InputLabel>
          <TextField
            placeholder={"0.0"}
            value={distance}
            onChange={(e) => handleChangeValue("distance", e.target.value)}
            className={"add-event__distance"}
          />
        </Box>
      </Box>
      <br />
      <Box sx={{ display: "flex", gap: "20px" }}>
        <Box>
          <InputLabel>Speed</InputLabel>
          <TextField
            value={speed}
            placeholder={"0"}
            onChange={(e) => handleChangeValue("speed", e.target.value)}
            className={"add-event__speed"}
          />
        </Box>
        <Box>
          <InputLabel>Elevation</InputLabel>
          <TextField
            placeholder={"0"}
            value={elevation}
            onChange={(e) => handleChangeValue("elevation", e.target.value)}
            className={"add-event__elevation"}
          />
        </Box>
      </Box>
      <br />
      <br />
      <InputLabel>Description</InputLabel>
      <TextAreaField
        placeholder={"My notes..."}
        sx={{ width: "100%" }}
        resize={"false"}
        rows={5}
        value={activityIndications}
        onChange={(e) =>
          handleChangeValue("activityIndications", e.target.value)
        }
      />
      <br />
      <InputLabel htmlFor="select">Activity Type</InputLabel>
      <CustomTabs
        value={activityType}
        handleChangeValue={(value) => handleChangeValue("activityType", value)}
        tabsData={TABS.SPORTS_TYPE}
      />
      <br />
      <InputLabel htmlFor="select">Add Other</InputLabel>
      <CustomTabs
        handleChangeValue={(value) => {}}
        tabsData={TABS.OTHER_TYPE}
      />
    </div>
  );
};

export default ManageEvent;
