import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const NoDataComponent = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      height: "100%",
    }}
  >
    <Typography
      sx={{
        color: "gray",
        opacity: 0.5,
        fontSize: "20px",
      }}
    >
      No Data
    </Typography>
  </Box>
);

export default NoDataComponent;
