import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Activities from "./pages/Activities/Activities";
import Dashboard from "./pages/Dashboard/Dashboard";
import Homepage from "./pages/Homepage/Homepage";
import Login from "./pages/Login/Login";
import NotFound from "./pages/NotFound/NotFound";
import Register from "./pages/Register/Register";
import Settings from "./pages/Settings";
import TrainingPlans from "./pages/TrainingPlans/TrainingPlans";
import Wellbeing from "./pages/Wellbeing/Wellbeing";
import "./style/main.scss";
import ProtectedRoute from "./utils/ProtectedRoute";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0F62FE",
      blue: "",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          position: "relative",
          label: {
            color: "#21272A",
            fontSize: "14px",
            marginBottom: "4px",
            top: "-5px",
            transform: "none",
            textTransform: "none",
          },
          input: {
            backgroundColor: "#F2F4F8",
            height: "46px",
            padding: "0 10px",
          },
          button: {
            padding: "8px 0 8px 0",
            position: "absolute",
            right: "25px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: "none",
          fontSize: "16px",
          fontWeight: "500",
        },
        containedPrimary: {
          color: "white",
          backgroundColor: "#0F62FE",
          "&:hover": {
            backgroundColor: "#094A8F",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#DDE1E6",
        },
      },
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute redirectTo="/login">
                  <Homepage />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="dashboard"
              element={
                <ProtectedRoute redirectTo="/login">
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="activities"
              element={
                <ProtectedRoute redirectTo="/login">
                  <Activities />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="trainingPlans"
              element={
                <ProtectedRoute redirectTo="/login">
                  <TrainingPlans />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="wellbeing"
              element={
                <ProtectedRoute redirectTo="/login">
                  <Wellbeing />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="settings"
              element={
                <ProtectedRoute redirectTo="/login">
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
