import { Stack, Typography } from "@mui/material";
import React from "react";
import AuthProvidersButtons from "../../components/AuthProvidersButtons/AuthProvidersButtons";

const SettingsPage = () => {
  return (
    <Stack spacing={3}>
      <Typography variant="h4" color={"gray"}>
        Connect with other apps
      </Typography>
      <AuthProvidersButtons />
    </Stack>
  );
};

export default SettingsPage;
