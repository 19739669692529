import { Paper, Stack, Typography } from "@mui/material";

const GoalsTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper sx={{ padding: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h6">{payload[0].payload.name}</Typography>
          <Typography variant="p">
            Goal reached:{" "}
            <Typography variant="span" sx={{ fontWeight: "bold" }}>
              {payload[0].payload.data}%
            </Typography>
          </Typography>
        </Stack>
      </Paper>
    );
  }

  return null;
};

export default GoalsTooltip;
