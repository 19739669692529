import { ModalDataActionsType } from "../actions/modalData.actions";

const initialState = {
  addEventModal: {
    activityType: "",
    date: "",
    activityTime: "",
    distance: "",
    speed: "",
    elevation: "",
    activityIndications: "",
  },
};

export const moadlDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ModalDataActionsType.SET_ADD_EVENT_MODAL:
      const { key, value } = action.payload;
      return {
        ...state,
        addEventModal: { ...state.addEventModal, [key]: value },
      };
    case ModalDataActionsType.RESTART_MODAL_STATE:
    case "RESTART_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
