import { Menu, MenuItem, Sidebar, useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";

import LogoutIcon from "@mui/icons-material/Logout";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { COLORS } from "../../common/defaults/colors.defaults";
import { SIDEBAR_WIDTH } from "../../constants/index";
import { restartStateAC } from "../../state/actions/restartState.actions";

const Item = ({ title, to, Icon, selected, setSelected }) => {
  const navigate = useNavigate();
  const { toggleSidebar, broken, rtl } = useProSidebar();

  return (
    <MenuItem
      active={selected === to}
      onClick={() => {
        setSelected(to);
        navigate(to);
        broken && !rtl && toggleSidebar();
      }}
      icon={<Icon width={30} />}
    >
      <Typography fontSize={18} fontWeight={500}>
        {title}
      </Typography>
    </MenuItem>
  );
};

const MyProSidebar = ({ links }) => {
  const selected = window.location.pathname;
  const { sidebarRTL, sidebarImage } = useSidebarContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 1,
        "& .ps-sidebar-container": {
          border: "none",
          background: COLORS.NAVIGATION_BG,
        },
        "& .ps-menu-icon svg path": {
          fill: "gray",
        },
        "& .ps-menu-button .ps-active svg path": {
          fill: "black",
        },
        "& .ps-menu-label": {
          color: "gray",
        },
        "& .ps-menu-button .ps-active": {
          color: "black",
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        // backgroundColor={COLORS.NAVIGATION_BG}
        width={SIDEBAR_WIDTH}
        image={sidebarImage}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            svg: { width: "auto", height: "40px", marginBottom: "50px" },
          }}
        >
          <Logo />
        </Box>
        <Menu
          iconshape="square"
          menuItemStyles={{
            [`&.ps-active`]: {
              backgroundColor: "#13395e",
              color: "#b6c8d9",
            },
          }}
        >
          <Box paddingLeft={"10px"}>
            {links.map(({ label, link, Icon }) => (
              <Item
                title={label}
                to={link}
                Icon={Icon}
                selected={selected}
                setSelected={() => {}}
              />
            ))}
          </Box>
        </Menu>
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            left: "30px",
            bottom: "30px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.removeItem("accessToken");
            navigate("/login");
            dispatch(restartStateAC());
          }}
        >
          <LogoutIcon width={30} />
          <Typography marginLeft={"10px"}>Log Out</Typography>
        </Box>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
