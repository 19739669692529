import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CustomCarousel = ({ children }) => {
  return (
    <Carousel
      showThumbs={true} // bottom images
      showStatus={false}  // top right 1/2
      showIndicators={false} // circles
      showArrows={false} // arrows
      infiniteLoop
      emulateTouch={true} // horizontal swiping
      swipeScrollTolerance={50}
      preventMovementUntilSwipeScrollTolerance={true} // prevent swiping to can scroll on mobile
      autoPlay
      useKeyboardArrows
      transitionTime={1000}
      thumbWidth={100}
      selectedItem={1}
      width="100%"
    >
      {children}
    </Carousel>
  );
};

export default CustomCarousel;
