import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { COLORS } from "../../../common/defaults/colors.defaults";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  name,
}) => {
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="gray"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${value}%`}
    </text>
  );
};

const PieChartComponent = ({ data, outerRadius = 70, isDashboard }) => {
  if (window.innerWidth >= 600) {
    outerRadius = isDashboard ? 80 : 120;
  } else {
    outerRadius = isDashboard ? 70 : 100;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Legend layout="horizontal" verticalAlign="bottom" align="center" />
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          label={renderCustomizedLabel}
          outerRadius={outerRadius}
          dataKey="value"
        >
          {data.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS.PIE_CHART[index % COLORS.PIE_CHART.length]}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
