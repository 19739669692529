import { Box } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { restartModalStateAC } from "../../state/actions/modalData.actions";
import { addEventAC } from "../../state/actions/user.actions";
import CustomModal from "../Modal/CustomModal";
import CustomEvent from "./CustomEvent/CustomEvent";
import ManageEvent from "./ManageEvent/ManageEvent";
import ViewEvent from "./ViewEvent/ViewEvent";
import LoaderComponent from "../LoaderComponent/LoaderComponent";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const Schadule = ({ events, isLoading }) => {
  const dispatch = useDispatch();

  const [manageModalOpen, setManageModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [modatTitle, setModalTitle] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedEvent, setSelectedEvent] = useState();

  const newEvent = useSelector((state) => state.moadlDataReducer.addEventModal);

  const handleAddEvent = () => {
    setManageModalOpen(false);
    dispatch(addEventAC({ ...newEvent }));
  };

  const handleSelect = ({ start, end }) => {
    // Parse the date string with moment
    dispatch(restartModalStateAC());
    setSelectedEvent();
    const date = moment.utc(start).add(1, "d");
    const formattedDate = date.format("DD MMM YYYY");
    setSelectedDate(formattedDate);
    setModalTitle(`Add event ${formattedDate}`);
    setManageModalOpen(true);
  };

  const eventPropGetter = (event) => {
    return {
      className: event.className, // You can also customize other properties here
      style: {
        backgroundColor: "#f1f1f1",
        color: "black",
        padding: 0,
        marginBottom: 3,
      },
      onClick: () => console.log("Event clicked!", event),
    };
  };
  return (
    <Box overflow={"auto"} zIndex={0}>
      <LoaderComponent show={isLoading} />
      <Calendar
        views={{
          month: true,
          week: false,
          agenda: true,
          // myweek: <div>1</div>,
        }}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView={window.innerWidth > 600 ? "month" : "agenda"}
        events={events.filter((el) => el.activityTime !== "0")}
        onSelectEvent={(event) => {
          setModalTitle(`View event ${event.date}`);
          setSelectedEvent(event);
          setViewModalOpen(true);
        }}
        onSelectSlot={handleSelect}
        // longPressThreshold={1}
        timeslots={3}
        eventPropGetter={eventPropGetter}
        components={{
          month: {
            event: CustomEvent,
          },
          agenda: {
            event: CustomEvent,
            // time: x
            // date: undefined,
          },
        }}
      />
      <CustomModal
        open={manageModalOpen}
        setOpen={setManageModalOpen}
        title={modatTitle}
        handleSave={handleAddEvent}
      >
        <ManageEvent date={selectedDate} />
      </CustomModal>
      <CustomModal
        open={viewModalOpen}
        setOpen={setViewModalOpen}
        title={modatTitle}
      >
        <ViewEvent event={selectedEvent} />
      </CustomModal>
    </Box>
  );
};

export default Schadule;
