import myFitnessPalSrc from "../../assets/apps/MyFitnessPal.png";
import tpSrc from "../../assets/apps/tp.png";
import wSrc from "../../assets/apps/w.png";
import zwiftSrc from "../../assets/apps/zwift.png";
export const providers = [
  {
    image: wSrc,
    alt: "W Icon",
  },
  {
    image: myFitnessPalSrc,
    alt: "My Fitness Pal Icon",
  },
  {
    image: tpSrc,
    alt: "Training Peaks Icon",
  },
  {
    image: zwiftSrc,
    alt: "Zwift Icon",
  },
];
