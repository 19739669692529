import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { CHARTS } from "../../../common/defaults/charts.defaults";
import { setLayoutValueAC } from "../../../state/actions/layout.actioms";

const GridLayoutEdit = ({ chartId, title, type, color, dataRange }) => {
  const dispatch = useDispatch();

  const handleChangeValue = (value, key) => {
    dispatch(setLayoutValueAC({ key: chartId, value, item: key }));
  };

  return (
    <div id="title__chart_type" title={title}>
      {type !== CHARTS.TYPE.PIE && (
        <>
          <Typography sx={{margin: "0.75rem 0" }}>Select Type of Chart</Typography>
          {CHARTS.CHART_OPTIONS.map(({ label, value }, index) => (
            <div
              key={`${chartId}_${label}_${index}`}
              className={`type-item ${value === type ? "selected" : ""}`}
              onClick={() => handleChangeValue(value, "type")}
            >
              {label}
            </div>
          ))}
          <p>Select Color of Chart</p>
          {CHARTS.CHART_COLOR_OPTIONS.map(({ label, value }, index) => (
            <div
              key={`${chartId}_${label}_${index}`}
              className={`type-item ${value === color ? "selected" : ""}`}
              onClick={() => handleChangeValue(value, "color")}
            >
              {label}
            </div>
          ))}
        </>
      )}
      <p>Select Date Range</p>
      {[
        { label: "This Week", value: "week" },
        { label: "This Month", value: "month" },
        { label: "This Year", value: "year" },
      ].map(({ label, value }, index) => (
        <div
          key={`${chartId}_${label}_${index}`}
          className={`type-item ${value === dataRange ? "selected" : ""}`}
          onClick={() => handleChangeValue(value, "dataRange")}
        >
          {label}
        </div>
      ))}
    </div>
  );
};

export default GridLayoutEdit;
