import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BatterySaverIcon from "@mui/icons-material/BatterySaver";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import { getStringTimeFromMinutes } from "../../../../services/manipulateData.service";
export const lastActivityData = (lastActivity) => [
  {
    label: "Total time",
    value: lastActivity.elapsed_time
      ? getStringTimeFromMinutes(lastActivity.elapsed_time)
      : "N/A",
    icon: <AccessTimeIcon />,
  },
  {
    label: "Total distance",
    value: lastActivity.distance
      ? `${(lastActivity.distance / 1000).toFixed(2)} km`
      : "N/A",
    icon: <SocialDistanceIcon />,
  },
  {
    label: "Activity type",
    value: lastActivity.type || "N/A",
    icon: <SportsGymnasticsIcon />,
  },
  {
    label: "Power",
    value: lastActivity.moving_time
      ? getStringTimeFromMinutes(lastActivity.moving_time)
      : "N/A",
    icon: <BatterySaverIcon />,
  },
  {
    label: "Calories",
    value: "N/A",
    icon: <LocalFireDepartmentIcon />,
  },
];
