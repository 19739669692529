import { UserActionsType } from "../actions/user.actions";

const initialState = {
  userData: {},
  activities: [],
  trainingPlan: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActionsType.SET_USER_DATA:
      return {
        ...state,
        userData: { ...action.payload.user },
        activities: [...action.payload.activities],
        trainingPlan: [...action.payload.trainingPlan],
      };
    case UserActionsType.SET_ALL_ACTIVITIES:
      return {
        ...state,
        allActivities: [...action.payload],
      };
    case UserActionsType.SET_EVENTS:
      return {
        ...state,
        trainingPlan: [...action.payload],
      };
    case UserActionsType.ADD_EVENT:
      return {
        ...state,
        trainingPlan: [...state.trainingPlan, action.payload],
      };
    case "RESTART_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
