export const setLayoutValueAC =
  ({ key, value, item }) =>
  (dispatch) => {
    dispatch({
      type: "SET_CHART_VALUE",
      payload: { key, value, item },
    });
  };

export const setLayoutAC = (breakpoint, newLayout) => (dispatch) => {
  dispatch({
    type: "SET_LAYOUT",
    payload: { breakpoint, newLayout },
  });
};

export const setBreakPointAC = (breakpoint) => (dispatch) => {
  dispatch({
    type: "SET_BREAKPOINT",
    payload: breakpoint,
  });
};
