import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { getUserData } from "../../state/thunks/user.thunk";
import LoaderComponent from "../LoaderComponent/LoaderComponent";
import { MyProSidebarProvider } from "../Sidebar/sidebarContext";
import Topbar from "../Sidebar/Topbar";

const LayoutContentBox = styled(Box)(({ theme }) => ({
  marginTop: "73px",
  paddingY: 4,
  width: "100%",
  height: "auto",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: 14,
  [theme.breakpoints.down("md")]: {
    padding: "10px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "20px 30px",
  },
}));

const Layout = () => {
  const userId = useSelector((state) => state.userReducer.userData.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("accessToken")) dispatch(getUserData());

    // eslint-disable-next-line
  }, []);

  return (
    // <Box>
    //   <Navbar isLogged={isLogger} />
    //   <CustomSidebar />
    //   <LayoutContentBox>
    //     <Outlet />
    //   </LayoutContentBox>
    // </Box>
    <MyProSidebarProvider>
      <div style={{ height: "100%", width: "100%", position: "relative" }}>
        <main>
          <Topbar />
          <LayoutContentBox>
            {localStorage.getItem("accessToken") && !userId ? (
              <LoaderComponent show />
            ) : (
              <Outlet />
            )}
          </LayoutContentBox>
        </main>
      </div>
    </MyProSidebarProvider>
  );
};

export default Layout;
