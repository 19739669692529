import { Typography } from "@mui/material";

const ViewEvent = ({ event }) => (
  <>
    <Typography>
      <b>Activity Type:</b> {event.activityType}
    </Typography>
    <br />
    <Typography>
      <b>Date:</b> {event.date}
    </Typography>
    <br />
    <Typography>
      <b>Time:</b> {event.activityTime}
    </Typography>
    <br />
    <Typography>
      <b>Distance:</b> {event.distance}
    </Typography>
    <br />
    <Typography>
      <b>Warm Up Exercises:</b> {event.warmUpExercises}
    </Typography>
    <br />
    <Typography>
      <b>Activity Indications:</b> {event.activityIndications}
    </Typography>
    <br />
    <Typography>
      <b>Recovery Indications:</b> {event.recoveryIndications}
    </Typography>
  </>
);

export default ViewEvent;
