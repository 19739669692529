import { combineReducers } from "redux";
import { layoutReducer } from "./layout.reducers";
import { moadlDataReducer } from "./modalData.reducers";
import { userReducer } from "./user.reducers";

export default combineReducers({
  layoutReducer,
  userReducer,
  moadlDataReducer,
});
