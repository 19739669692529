import fetchDataFromBackend from "../../services/fetchDataFromBackend.service";
import { setUserDataAC } from "../actions/user.actions";

export const getUserData = () => async (dispatch) => {
  try {
    const [userData, allActivities] = await Promise.all([
      fetchDataFromBackend("/users/users/me"),
      fetchDataFromBackend("/services/strava/activities"),
    ]);

    const { training, ...user } = userData;
    const allUserData = {
      user: user,
      trainingPlan: training[0]?.plan || [],
      activities: allActivities ? [...allActivities] : [],
    };

    dispatch(setUserDataAC(allUserData));
  } catch (err) {
    console.log(err);
  }
};
