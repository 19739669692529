import { data } from "./data";

const gradientOffset = () => {
  const dataMax = Math.max(...data.map((i) => i.form));
  const dataMin = Math.min(...data.map((i) => i.form));

  if (dataMax <= 50) {
    return 0;
  }
  if (dataMin >= 50) {
    return 1;
  }
  // Does not work as expected - the example website is wrong
  return 0.8
};

export const off = gradientOffset();