import { Box, Paper, styled } from "@mui/material";

const Contaner = styled(Paper)({
  background: "white",
  boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)",
  padding: "10px",
  color: "gray",
  height: "400px",
  borderRadius: "10px",
});

const Title = styled("p")({
  margin: "10px",
  color: "gray",
  fontSize: "16px",
  fontWeight: "bold",
});

const Content = styled(Box)({
  width: "100%",
  height: "calc(100% - 50px)",
});

const Card = ({ title, children, sx }) => {
  return (
    <Contaner sx={{ ...sx }}>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Contaner>
  );
};

export default Card;
