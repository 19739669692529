import RouteIcon from '@mui/icons-material/Route';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const SIDEBAR_LINKS = [
  { label: "Home", link: "/", Icon: HomeOutlinedIcon },
  { label: "Dashboard", link: "/dashboard", Icon: DashboardCustomizeOutlinedIcon },
  { label: "Activities", link: "/activities", Icon: RouteIcon },
  { label: "Training plans", link: "/trainingPlans", Icon: EventAvailableIcon },
  { label: "Well-being", link: "/wellbeing", Icon: FavoriteBorderIcon },
  { label: "Settings", link: "/settings", Icon: SettingsOutlinedIcon },
];

export default SIDEBAR_LINKS;
