import React from "react";
import { useSelector } from "react-redux";
import {
  Legend,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import Card from "../../../Card/Card";
import NoDataComponent from "../../../NoDataComponent/NoDataComponent";
import { data } from "./data";
import GoalsTooltip from "./goalsTooltip";

const Goals = () => {
  const activities = useSelector((state) => state.userReducer.activities);

  return (
    <Card title={"Goals"}>
      {activities.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <RadialBarChart
            cx={window.innerWidth >= 600 ? "30%" : "50%"}
            cy="50%"
            innerRadius="10%"
            outerRadius="80%"
            barSize={10}
            data={data}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <RadialBar minAngle={15} background clockWise dataKey="data" />
            {window.innerWidth >= 600 ? (
              <Legend
                iconSize={10}
                layout="vertical"
                verticalAlign="middle"
                wrapperStyle={{
                  right: "20%",
                  top: "50%",
                  transform: "translate(50%, -50%)",
                  lineHeight: "24px",
                }}
              />
            ) : (
              <Legend iconSize={10} layout="horizontal" />
            )}
            <Tooltip content={<GoalsTooltip />} />
          </RadialBarChart>
        </ResponsiveContainer>
      ) : (
        <NoDataComponent />
      )}
    </Card>
  );
};

export default Goals;
