import React from "react";
import PropTypes from "prop-types";
import { CHARTS } from "../../common/defaults/charts.defaults";
import PieChartComponent from "./PieChartComponent/PieChartComponent";
import AreaChartComponent from "./AreaChartComponent/AreaChartComponent";
import BarChartComponent from "./BarChartComponent/BarChartComponent";
import LineChartComponent from "./LineChartComponent/LineChartComponent";

const ChartComponent = ({ type, ...rest }) => {
  switch (type) {
    case CHARTS.TYPE.PIE:
      return <PieChartComponent {...rest} />;
    case CHARTS.TYPE.LINE:
      return <LineChartComponent {...rest}/>;
    case CHARTS.TYPE.BAR:
      return <BarChartComponent {...rest} />;
    case CHARTS.TYPE.AREA:
      return <AreaChartComponent {...rest} />;
    default:
      return null;
  }
};

ChartComponent.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default ChartComponent;
