import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";

const style = {
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  maxWidth: "60%",
};

const CustomModal = ({
  open,
  setOpen,
  title,
  children,
  butonLabel = "Save",
  handleSave,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        placeItems: "center",
      }}
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{
            fontWeight: 900,
            textDecoration: "underline",
            marginBottom: "30px",
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            maxHeight: window.innerWidth >= 600 ? "60vh" : "90vh",
            overflow: "auto",
          }}
        >
          {children}
        </Box>
        <Grid
          item
          paddingTop={4}
          spacing={2}
          container
          xs={12}
          justifyContent="flex-end"
          gap={1}
          width={"100%"}
          marginLeft={0}
        >
          <Button className="cancel-button" onClick={() => setOpen(false)}>
            Close
          </Button>
          {handleSave && (
            <Button
              className="standard-button"
              sx={{ border: "1px solid rgba(0,0,0,.1)", borderRadius: "5px" }}
              onClick={() => {
                handleSave();
              }}
            >
              {butonLabel}
            </Button>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default CustomModal;
