import axios from 'axios';
import { API_BASE_URL } from '../common/defaults/urls.defaults';

export const getGarminToken = async (jwt) => {
  try {
    const options = {
      method: 'get',
      url: `${API_BASE_URL}/services/garmin/token`,
      headers: {
        "content-type": 'application/json',
        'Authorization': `Bearer ${jwt}`
      }
    }

    const res = await axios(options);
    return res.data.oauthToken;
  } catch (err) {
    console.log(err);
  }
}