export const data = [
  {
    name: "week 1 ",
    form: 90,
  },
  {
    name: "week 2 ",
    form: 70,
  },
  {
    name: "week 3 ",
    form: 60,
  },
  {
    name: "week 4 ",
    form: 40,
  },
  {
    name: "week 5 ",
    form: 70,
  },
  {
    name: "week 6 ",
    form: 40,
  },
  {
    name: "week 7 ",
    form: 90,
  },
];
