import React from "react";
import Fitness from "./fitness";
import Goals from "./goals";
import Wellbeing from "./wellbeing";

const OverallSection = () => {
  return (
    <>
      <Fitness gridArea="a" />
      <Wellbeing gridArea="b" />
      <Goals gridArea="c" />
    </>
  );
};

export default OverallSection;
