import React from "react";
import { useSelector } from "react-redux";
import { mockOverallWellbeing } from "../../../../data/mockData";
import Card from "../../../Card/Card";
import { CustomActiveShapePieChart } from "../../../ChartComponent/CustomActiveShapePieChart/CustomActiveShapePieChart";
import NoDataComponent from "../../../NoDataComponent/NoDataComponent";

const Wellbeing = () => {
  const lastActivity = useSelector((state) => state.userReducer.activities);

  return (
    <Card title="Well-being overview">
      {lastActivity.length > 0 ? (
        <CustomActiveShapePieChart
          data={mockOverallWellbeing}
          isHomepage
          innerRadius={100}
          outerRadius={120}
        />
      ) : (
        <NoDataComponent />
      )}
    </Card>
  );
};

export default Wellbeing;
