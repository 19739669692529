export const COLORS = {
  SPORT: {
    RUN: "#F7A88D",
    RIDE: "#BFF786",
    SWIM: "#8DA0F7",
    STRENGHT: "#F7D78D",
  },
  NAVIGATION_BG: "white",
  DASHBOARD_COLORS: {
    RED: "red",
    BLUE: "blue",
    GREEN: "green",
  },
  PIE_CHART: ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"],
};
