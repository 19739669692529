import axios from "axios";

const BASE_URL = "https://sportshub.ink";

async function login(email, password) {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, { email, password });
    return response.data.accessToken;
  } catch (error) {
    alert(error.message);
  }
}

async function register(name, email, password) {
  try {
    const response = await axios.post(`${BASE_URL}/auth/register`, { name, password, email });
    return response.data.accessToken;
  } catch (error) {
    alert(error.message);
  }
}

export const AuthenticationService = {
  login,
  register,
};
