export const moockStressLevel = [
  { value: 100 },
  { value: 480 },
  { value: 150 },
  { value: 380 },
  { value: 80 },
  { value: 440 },
  { value: 120 },
  { value: 340 },
  { value: 210 },
  { value: 420 },
  { value: 180 },
  { value: 290 },
  { value: 260 },
  { value: 190 },
  { value: 340 },
  { value: 270 },
  { value: 100 },
  { value: 420 },
  { value: 140 },
  { value: 330 },
  { value: 150 },
  { value: 380 },
  { value: 280 },
  { value: 400 },
  { value: 220 },
  { value: 450 },
  { value: 80 },
  { value: 420 },
  { value: 120 },
  { value: 340 },
  { value: 190 },
  { value: 260 },
  { value: 210 },
  { value: 420 },
  { value: 150 },
  { value: 330 },
  { value: 100 },
  { value: 420 },
  { value: 280 },
  { value: 380 },
  { value: 450 },
  { value: 80 },
  { value: 190 },
  { value: 260 },
  { value: 330 },
  { value: 210 },
  { value: 420 },
  { value: 150 },
  { value: 380 },
  { value: 280 },
  { value: 450 },
  { value: 80 },
  { value: 190 },
  { value: 260 },
  { value: 330 },
  { value: 210 },
  { value: 420 },
  { value: 150 },
  { value: 380 },
  { value: 280 },
  { value: 450 },
  { value: 80 },
  { value: 190 },
  { value: 260 },
  { value: 330 },
  { value: 210 },
  { value: 420 },
  { value: 150 },
  { value: 380 },
  { value: 280 },
  { value: 450 },
  { value: 80 },
  { value: 190 },
  { value: 260 },
  { value: 330 },
  { value: 210 },
  { value: 420 },
  { value: 150 },
  { value: 380 },
  { value: 280 },
  { value: 450 },
  { value: 80 },
  { value: 190 },
  { value: 260 },
  { value: 330 },
  { value: 210 },
  { value: 420 },
  { value: 150 },
  { value: 380 },
  { value: 280 },
  { value: 450 },
  { value: 80 },
  { value: 190 },
  { value: 260 },
  { value: 330 },
  { value: 210 },
  { value: 420 },
  { value: 150 },
  { value: 380 },
  { value: 280 },
  { value: 450 },
];

export const mockHRV = [
  { value: 50 },
  { value: 55 },
  { value: 60 },
  { value: 52 },
  { value: 58 },
  { value: 54 },
  { value: 62 },
  { value: 48 },
  { value: 56 },
  { value: 53 },
  { value: 59 },
  { value: 49 },
  { value: 57 },
  { value: 61 },
  { value: 51 },
  { value: 55 },
  { value: 52 },
  { value: 58 },
  { value: 54 },
  { value: 50 },
  { value: 56 },
  { value: 53 },
  { value: 59 },
  { value: 51 },
  { value: 57 },
  { value: 60 },
  { value: 52 },
  { value: 58 },
  { value: 54 },
  { value: 49 },
  { value: 55 },
  { value: 53 },
  { value: 61 },
  { value: 47 },
  { value: 56 },
  { value: 50 },
  { value: 58 },
  { value: 52 },
  { value: 60 },
  { value: 54 },
  { value: 49 },
  { value: 55 },
  { value: 51 },
  { value: 57 },
  { value: 53 },
  { value: 59 },
  { value: 46 },
  { value: 50 },
  { value: 56 },
  { value: 52 },
  { value: 58 },
  { value: 54 },
  { value: 49 },
  { value: 55 },
  { value: 51 },
  { value: 57 },
  { value: 53 },
  { value: 59 },
  { value: 46 },
  { value: 50 },
  { value: 56 },
  { value: 52 },
  { value: 58 },
  { value: 54 },
  { value: 49 },
  { value: 55 },
  { value: 51 },
  { value: 57 },
  { value: 53 },
  { value: 59 },
  { value: 46 },
  { value: 50 },
  { value: 56 },
  { value: 52 },
  { value: 58 },
  { value: 54 },
  { value: 49 },
  { value: 55 },
  { value: 51 },
  { value: 57 },
  { value: 53 },
  { value: 59 },
  { value: 46 },
  { value: 50 },
  { value: 56 },
  { value: 52 },
  { value: 58 },
  { value: 54 },
  { value: 49 },
  { value: 55 },
  { value: 51 },
  { value: 57 },
  { value: 53 },
  { value: 59 },
  { value: 46 },
  { value: 50 },
  { value: 56 },
  { value: 52 },
  { value: 58 },
  { value: 54 },
  { value: 49 },
  { value: 55 },
  { value: 51 },
  { value: 57 },
  { value: 53 },
  { value: 59 },
  { value: 46 },
  { value: 50 },
  { value: 56 },
  { value: 52 },
  { value: 58 },
  { value: 54 },
  { value: 49 },
  { value: 55 },
  { value: 51 },
  { value: 57 },
  { value: 53 },
  { value: 59 },
  { value: 46 },
];

export const mockSleep = [
  { name: "Deep", value: 37 },
  { name: "Light", value: 49 },
  { name: "Rem", value: 13 },
  { name: "Awake", value: 1 },
];

export const mockMacronutrients = [
  { carbs: 200, proteins: 80, fat: 60 },
  { carbs: 180, proteins: 75, fat: 55 },
  { carbs: 220, proteins: 85, fat: 65 },
  { carbs: 195, proteins: 70, fat: 50 },
  { carbs: 210, proteins: 90, fat: 70 },
  { carbs: 185, proteins: 65, fat: 55 },
  { carbs: 225, proteins: 95, fat: 75 },
  { carbs: 200, proteins: 80, fat: 60 },
  { carbs: 190, proteins: 75, fat: 55 },
  { carbs: 230, proteins: 85, fat: 65 },
  { carbs: 205, proteins: 70, fat: 50 },
  { carbs: 215, proteins: 90, fat: 70 },
  { carbs: 195, proteins: 65, fat: 55 },
  { carbs: 235, proteins: 95, fat: 75 },
  { carbs: 210, proteins: 80, fat: 60 },
  { carbs: 200, proteins: 75, fat: 55 },
  { carbs: 240, proteins: 85, fat: 65 },
  { carbs: 215, proteins: 70, fat: 50 },
  { carbs: 225, proteins: 90, fat: 70 },
  { carbs: 205, proteins: 65, fat: 55 },
  { carbs: 245, proteins: 95, fat: 75 },
  { carbs: 220, proteins: 80, fat: 60 },
  { carbs: 210, proteins: 75, fat: 55 },
  { carbs: 250, proteins: 85, fat: 65 },
  { carbs: 225, proteins: 70, fat: 50 },
  { carbs: 235, proteins: 90, fat: 70 },
  { carbs: 215, proteins: 65, fat: 55 },
  { carbs: 255, proteins: 95, fat: 75 },
  { carbs: 230, proteins: 80, fat: 60 },
  { carbs: 220, proteins: 75, fat: 55 },
  { carbs: 260, proteins: 85, fat: 65 },
  { carbs: 235, proteins: 70, fat: 50 },
  { carbs: 245, proteins: 90, fat: 70 },
  { carbs: 225, proteins: 65, fat: 55 },
  { carbs: 265, proteins: 95, fat: 75 },
  { carbs: 240, proteins: 80, fat: 60 },
  { carbs: 230, proteins: 75, fat: 55 },
  { carbs: 270, proteins: 85, fat: 65 },
  { carbs: 245, proteins: 70, fat: 50 },
  { carbs: 255, proteins: 90, fat: 70 },
  { carbs: 235, proteins: 65, fat: 55 },
  { carbs: 275, proteins: 95, fat: 75 },
  { carbs: 250, proteins: 80, fat: 60 },
  { carbs: 240, proteins: 75, fat: 55 },
  { carbs: 280, proteins: 85, fat: 65 },
  { carbs: 255, proteins: 70, fat: 50 },
  { carbs: 265, proteins: 90, fat: 70 },
  { carbs: 245, proteins: 65, fat: 55 },
];

export const mockOverallWellbeing = [
  { name: "Sleep", value: 25, show: "83%" },
  { name: "Stress", value: 25, show: "25 total" },
  { name: "HRV", value: 25, show: "53 ms" },
  { name: "Calorii", value: 25, show: "2483 kcal" },
];
