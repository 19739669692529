import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  TextField,
  Button,
  InputLabel,
  IconButton,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Apple, Google, Visibility, VisibilityOff } from "@mui/icons-material";
import { AuthenticationService } from "../../services/authentication.service";
import AuthenticationLayout from "../../components/AuthenticationLayout/AuthenticationLayout";

const ProviderButton = styled(Button)({
  marginTop: "16px",
  backgroundColor: "transparent",
  color: "#0F62FE",
  border: "2px solid #0F62FE",
  borderRadius: 0,
  "&:hover": {
    backgroundColor: "rgba(0, 0, 255, 0.1)",
  },
});

const DividerLine = styled(Divider)({
  borderColor: "#DDE1E6",
  margin: "48px 0",
});

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const responseBody = {};
    const formData = new FormData(event.currentTarget);
    formData.forEach((value, property) => (responseBody[property] = value));
    const accessToken = await AuthenticationService.login(
      responseBody.email,
      responseBody.password
    );

    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      navigate("/");
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthenticationLayout title={"Log In"}>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
        />
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          id="password"
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
        <InputLabel sx={{ fontSize: "14px", whiteSpace: "wrap" }}>
          It must be a combination of minimum 8 letters, numbers, and symbols.
        </InputLabel>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 2 }}
        >
          <Grid item>
            <FormControlLabel control={<Checkbox />} label="Remember me" />
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2, backgroundColor: "#0F62FE", borderRadius: 0 }}
        >
          Log In
        </Button>
      </form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ProviderButton
            variant="contained"
            color="secondary"
            startIcon={<Google />}
            sx={{ width: "100%" }}
          >
            Log in with Google
          </ProviderButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProviderButton
            variant="contained"
            color="primary"
            startIcon={<Apple />}
            sx={{ width: "100%" }}
          >
            Log in with Apple
          </ProviderButton>
        </Grid>
      </Grid>
      <DividerLine />
      <Grid item xs={12} sm={6} sx={{ marginTop: 2 }}>
        <Link to="/register" variant="body2">
          No account yet? Sign Up
        </Link>
      </Grid>
    </AuthenticationLayout>
  );
};

export default Login;
