import { Base64 } from "js-base64";

export const jwtExtractorUtil = (token) => {
  if (!token) {
    return {};
  }
  const s = token.split(".");
  const details = Base64.decode(s[1]);
  const data = JSON.parse(details);
  return {
    ...data,
  };
};
