import { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomTable from "../../components/Table/CustomTable";
import moment from "moment";
import { useState } from "react";
import CustomModal from "../../components/Modal/CustomModal";
import { Typography } from "@mui/material";
import { getStringTimeFromMinutes } from "../../services/manipulateData.service";
import stravaMockActivity from "../../assets/strava-mock-activity.png";
import stravaMockOdherSports from "../../assets/strava-mock-other-sports.png";
import { Box } from "@mui/system";

const Activities = () => {
  const [activitiesForTable, setActivitiesForTable] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState();
  const allActivities = useSelector((state) => state.userReducer.activities);

  useEffect(() => {
    if (allActivities?.length > 0) {
      const acivitiesTable = allActivities.map((activity, index) => {
        const elapsedTime = moment.duration(activity.elapsed_time, "seconds");
        const formattedElapsedTime = moment
          .utc(elapsedTime.asMilliseconds())
          .format("HH:mm:ss");

        return {
          name: activity.name,
          type: activity.type,
          distance: `${Number(parseInt(activity.distance) / 1000).toFixed(
            2,
          )} km`,
          elapsedTime: formattedElapsedTime,
          data: activity.start_date_local.split("T")[0],
        };
      });
      setActivitiesForTable(acivitiesTable);
    }

    // eslint-disable-next-line
  }, [allActivities]);

  const handleClickLink = (index) => {
    setSelectedActivity(allActivities[index]);
  };

  return (
    <>
      <CustomTable
        header={[
          { label: "Name", key: "name" },
          { label: "Sport Type", key: "type" },
          { label: "Distance", key: "distance" },
          { label: "Elapsed Time", key: "elapsedTime" },
          { label: "Data", key: "data" },
        ]}
        rows={activitiesForTable}
        showIndex
        isLinkFirst
        handleClickLink={handleClickLink}
      />
      <CustomModal
        open={!!selectedActivity}
        setOpen={setSelectedActivity}
        title={selectedActivity?.name}
      >
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateRows: "auto",
            gridTemplateAreas: `"a a" "b c" "d e" "f g"`,
            "@media (max-width:1130px)": {
              gridTemplateAreas: `"a" "b" "c" "d" "e" "f" "g"`,
            },
          }}
        >
          <Typography gridArea="a">
            <b>Activity Type:</b> {selectedActivity?.type}
          </Typography>
          
          <Typography gridArea="b">
            <b>Date:</b> {selectedActivity?.start_date_local?.split("T")[0]}
          </Typography>
          
          <Typography gridArea="c">
            <b>Start Time:</b>{" "}
            {selectedActivity?.start_date_local?.split("T")[1].slice(0, -1)}
          </Typography>
          
          <Typography gridArea="d">
            <b>Distance:</b> {(selectedActivity?.distance / 1000).toFixed(2)} km
          </Typography>
          
          <Typography gridArea="e">
            <b>Total Elevation Gain:</b>{" "}
            {selectedActivity?.total_elevation_gain || 0} m
          </Typography>
          
          <Typography gridArea="f">
            <b>Elapsed Time:</b>{" "}
            {getStringTimeFromMinutes(selectedActivity?.elapsed_time)}
          </Typography>
          
          <Typography gridArea="g">
            <b>Moving Time:</b>{" "}
            {getStringTimeFromMinutes(selectedActivity?.moving_time)}
          </Typography>
        </Box>
        <br />
        {window.innerHeight >= 850 && (
          <Box overflow={"auto"}>
            {["Run", "Ride"].includes(selectedActivity?.type) ? (
              <img
                src={stravaMockActivity}
                alt={"Activity data"}
                loading="lazy"
                width={"500px"}
                height={"auto"}
              />
            ) : (
              <img
                src={stravaMockOdherSports}
                alt={"Activity data"}
                loading="lazy"
                width={"500px"}
              />
            )}
          </Box>
        )}
      </CustomModal>
    </>
  );
};

export default Activities;
