import { useTheme } from "@emotion/react";
import { Backdrop } from "@mui/material";
import loadingAnimation from "../../assets/loading-animation.gif";

const LoaderComponent = ({ show }) => {
  const theme = useTheme();

  return (
    <Backdrop
      sx={{
        backgroundColor: "rgba(240, 240, 240, 0.5)",
        backdropFilter: `blur(${theme.typography.pxToRem(3)})`,
        position: "absolute",
        top: "73px",
        height: "calc(100vh - 73px)",
        color: "#aaf",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        img: {
          width: "70px",
          height: "auto",
        },
      }}
      open={show}
    >
      <img alt="Loading..." src={loadingAnimation} />
    </Backdrop>
  );
};

export default LoaderComponent;
