const getCurrentBreakpoint = () => {
  const width = window.innerWidth;
  // Define your own logic to determine the breakpoint based on the width
  if (width > 1280) return "lg";
  if (width > 992) return "md";
  if (width > 767) return "sm";
  if (width > 480) return "xs";
  return "xxs";
};

export { getCurrentBreakpoint };
