export const UserActionsType = {
  SET_USER_DATA: "SET_USER_DATA",
  SET_EVENTS: "SET_EVENTS",
  ADD_EVENT: "ADD_EVENT",
  DELETE_EVENT: "DELETE_EVENT",
  SET_ALL_ACTIVITIES: "SET_ALL_ACTIVITIES",
  SET_ACTIVITY_ID: "SET_ACTIVITY_ID",
};

export const setUserDataAC = (data) => ({
  type: UserActionsType.SET_USER_DATA,
  payload: data,
});

export const setEventsAC = (events) => ({
  type: UserActionsType.SET_EVENTS,
  payload: events,
});

export const addEventAC = (event) => ({
  type: UserActionsType.ADD_EVENT,
  payload: event,
});

export const setAllActivitiesAC = (allActivities) => ({
  type: UserActionsType.SET_ALL_ACTIVITIES,
  payload: allActivities,
});

export const setActivityIdAC = (id) => ({
  type: UserActionsType.SET_ACTIVITY_ID,
  payload: id,
});
