export const CHARTS = {
  TYPE: {
    LINE: "LINE",
    PIE: "PIE",
    AREA: "AREA",
    BAR: "BAR",
  },
  CHART_COLOR_OPTIONS: [
    {
      label: "Red",
      value: "red",
    },
    {
      label: "Blue",
      value: "blue",
    },
    {
      label: "Green",
      value: "green",
    },
  ],
  CHART_OPTIONS: [
    {
      label: "Line",
      value: "LINE",
    },
    // {
    //   label: "Pie",
    //   value: "PIE",
    // },
    {
      label: "Area",
      value: "AREA",
    },
    {
      label: "Bar",
      value: "BAR",
    },
  ],
  YEAR: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  WEEK: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  DASHBOARD_CHARTS: {
    graph1: {
      title: "Distance: All Workout Types",
      key: "distance",
      type: "AREA",
      color: "red",
      dataRange: "month",
    },
    graph2: {
      title: "Fitness Summary: Completed Distance",
      key: "distance-pie",
      type: "PIE",
      dataRange: "month",
    },
    graph3: {
      title: "Duration: All Workout Types",
      key: "moving_time",
      type: "BAR",
      color: "green",
      dataRange: "month",
    },
    graph4: {
      title: "Fitness Summary: Completed Duration",
      key: "moving_time-pie",
      type: "PIE",
      dataRange: "month",
    },
    graph5: {
      title: "Duration: All Workout Types",
      key: "moving_time",
      type: "AREA",
      color: "blue",
      dataRange: "year",
    },
    graph6: {
      title: "Duration: All Workout Types",
      key: "moving_time",
      type: "LINE",
      color: "red",
      dataRange: "week",
    },
    graph7: {
      title: "Add New Chart",
    },
  },
};
