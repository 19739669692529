import React, { useState } from "react";
import { Grid, TextField, Button, InputLabel, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AuthenticationService } from "../../services/authentication.service";
import AuthenticationLayout from "../../components/AuthenticationLayout/AuthenticationLayout";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const responseBody = {};
    const formData = new FormData(event.currentTarget);
    formData.forEach((value, property) => (responseBody[property] = value));
    const accessToken = await AuthenticationService.register(
      responseBody.name,
      responseBody.email,
      responseBody.password
    );

    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      navigate("/");
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthenticationLayout title={"Register"}>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
        />
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
        />
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          id="password"
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
        <InputLabel sx={{ fontSize: "14px", whiteSpace: "wrap" }}>
          It must be a combination of minimum 8 letters, numbers, and symbols.
        </InputLabel>
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          name="confirm"
          label="Confirm Password"
          type={showPassword ? "text" : "password"}
          id="confirm"
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2, backgroundColor: "#0F62FE", borderRadius: 0 }}
        >
          Register
        </Button>
      </form>
      <Grid item xs={12} sm={6} sx={{ marginTop: 2 }}>
        <Link to="/login" variant="body2">
          Already have an account? Log In
        </Link>
      </Grid>
    </AuthenticationLayout>
  );
};

export default Register;
