import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import {
  Box,
  Divider,
  IconButton,
  InputLabel,
  Tooltip,
  useTheme,
} from "@mui/material";
import React from "react";
import { useProSidebar } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import garminIcon from "../../assets/garmin-icon.svg";
import stravaIcon from "../../assets/strava-icon.svg";
import { COLORS } from "../../common/defaults/colors.defaults";
import { GARMIN, STRAVA } from "../../constants";

const Topbar = () => {
  const theme = useTheme();
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const userData = useSelector((state) => state.userReducer.userData);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      sx={{
        background: COLORS.NAVIGATION_BG,
        borderBottom: "1px solid #e3e3e3",
        position: "fixed",
        width: !broken ? "calc(100% - 250px)" : "100%",
        boxSizing: "border-box",
        top: "0",
        opacity: "1",
        zIndex: 2,
      }}
    >
      <Box display="flex">
        {broken && !rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
      <Box display="flex" sx={{ alignItems: "center" }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Tooltip
            title={
              userData?.services?.some(({ service }) => service === STRAVA)
                ? "Connected with Strava"
                : "Not Connected with Strava"
            }
          >
            <Box
              sx={{
                padding: 0.5,
                borderRadius: "50%",
                backgroundColor: "#eee",
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${stravaIcon})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  height: theme.typography.pxToRem(20),
                  width: theme.typography.pxToRem(20),
                  opacity: userData?.services?.some(
                    ({ service }) => service === STRAVA,
                  )
                    ? 1
                    : 0.3,
                }}
              />
            </Box>
          </Tooltip>
          <Tooltip
            title={
              userData?.services?.some(({ service }) => service === GARMIN && false )
                ? "Connected with Garmin"
                : "Not Connected with Garmin"
            }
          >
            <Box
              sx={{
                padding: 0.5,
                borderRadius: "50%",
                backgroundColor: "#eee",
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${garminIcon})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  height: theme.typography.pxToRem(20),
                  width: theme.typography.pxToRem(20),
                  opacity: userData?.services?.some(
                    ({ service }) => service === GARMIN && false, 
                  )
                    ? 1
                    : 0.3,
                }}
              />
            </Box>
          </Tooltip>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        <IconButton>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <AccountCircleIcon />
        </IconButton>
        <InputLabel>{userData.name}</InputLabel>
        {broken && rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
