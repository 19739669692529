import { CHARTS } from "../../common/defaults/charts.defaults";
import { layoutConfig } from "../../common/defaults/layoutConfig.defaults";
import { getCurrentBreakpoint } from "../../services/gridLayout.service";

const initialState = {
  charts: CHARTS.DASHBOARD_CHARTS,
  layouts: layoutConfig,
  breakpoint: getCurrentBreakpoint(),
};

export const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CHART_VALUE": {
      const { key, value, item } = action.payload;
      return {
        ...state,
        charts: {
          ...state.charts,
          [key]: {
            ...state.charts[key],
            [item]: value,
          },
        },
      };
    }
    case "SET_LAYOUT": {
      const { newLayout, breakpoint } = action.payload;
      console.log(newLayout, breakpoint);
      return {
        ...state,
        // layouts: { ...state.layouts, [breakpoint]: newLayout },
      };
    }
    case "SET_BREAKPOINT":
      return {
        ...state,
        breakpoint: action.payload,
      };
    case "RESTART_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
